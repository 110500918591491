<template>
  <section id="a-products">
    <b-card>
      <b-row>
        <b-col xl="2" md="2">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="CalendarIcon" />
            </b-input-group-prepend>
            <flat-pickr v-model="dateRange" placeholder="Date Range" class="form-control" :config="{ mode: 'range' }"
              @on-change="changeDateRange" />
          </b-input-group>
        </b-col>
        <b-col xl="4" md="4">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input v-model="searchValue" placeholder="Search..." @keyup.enter="searchProduct" />
          </b-input-group>
        </b-col>
        <b-col xl="3" md="3">
          <v-select v-model="selectedBrand" placeholder="Select brand" label="title" :options="allBrands"
            @input="changeSelectedBrand" />
        </b-col>
      </b-row>
    </b-card>
    <b-overlay :show="show" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">
            A-Products
            <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-a-products" />
          </h4>
          <b-popover target="popover-a-products" triggers="hover" placement="bottom">
            <span>No data</span>
          </b-popover>
          <b-card-text class="font-medium-5 mb-0">
            <b-overlay :show="showExportStart" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
              <feather-icon icon="DownloadIcon" size="21" class="text-muted cursor-pointer" @click="exportTable" />
            </b-overlay>
          </b-card-text>
        </b-card-header>
        <b-table striped hover responsive class="position-relative" :current-page="AProdcutsTable.currentPage"
          :items="AProdcutsTable.items" :fields="AProdcutsTable.fields" :sort-by.sync="AProdcutsTable.sortBy"
          :sort-desc.sync="AProdcutsTable.sortDesc" :sort-direction="AProdcutsTable.sortDirection"
          :filter="AProdcutsTable.filter" :filter-included-fields="AProdcutsTable.filterOn" @sort-changed="sortChanged"
          @row-clicked="productDetailsRowClicked" />
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- pagination -->
          <div>
            <b-pagination v-model="AProdcutsTable.currentPage" :total-rows="AProdcutsTable.totalRows" first-number
              last-number prev-class="prev-item" next-class="next-item" class="mb-0"
              @change="handleAProdcutsTablePageChange">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card>
    </b-overlay>
    <b-modal ref="product-details-modal" title="Details" size="xl" cancel-title="Close"
      cancel-variant="outline-secondary" :no-close-on-backdrop="true" @hidden="resetProductDetailsModal">
      <b-card no-body>
        <b-table striped hover responsive class="position-relative" :current-page="productDetailsTable.currentPage"
          :items="productDetailsTable.items" :fields="productDetailsTable.fields"
          :sort-by.sync="productDetailsTable.sortBy" :sort-desc.sync="productDetailsTable.sortDesc"
          :sort-direction="productDetailsTable.sortDirection" :filter="productDetailsTable.filter"
          :filter-included-fields="productDetailsTable.filterOn">
          <template #cell(link_to_product)="data">
            <a :href="data.item.link_to_product" target="_blank">
              {{ data.item.link_to_product }}
            </a>
          </template>
        </b-table>
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- pagination -->
          <div>
            <b-pagination v-model="productDetailsTable.currentPage" :total-rows="productDetailsTable.totalRows"
              first-number last-number prev-class="prev-item" next-class="next-item" class="mb-0"
              @change="handleProductDetailsTablePageChange">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card>
    </b-modal>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BTable,
  BPagination,
  BCardBody,
  BCardHeader,
  BCardText,
  BPopover,
  BCard,
  BOverlay,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BModal,
} from 'bootstrap-vue';
import flatPickr from 'vue-flatpickr-component';
import vSelect from 'vue-select';
import axios from 'axios';
import * as XLSX from 'xlsx';

const currentDate = new Date();
const twelveMonthsAgo = new Date();
twelveMonthsAgo.setMonth(currentDate.getMonth() - 11);
const formatMonth = (date) => String(date.getMonth() + 1).padStart(2, '0');
const formatDate = (date) => String(date.getDate()).padStart(2, '0');

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BPagination,
    BCard,
    BCardBody,
    BCardHeader,
    BCardText,
    BPopover,
    BOverlay,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BModal,
    flatPickr,
    vSelect,
  },
  data() {
    return {
      show: true,
      showProductDetails: true,
      showExportStart: false,
      oldDateRange: `${twelveMonthsAgo.getFullYear()}-${formatMonth(twelveMonthsAgo)}-01 to ${currentDate.getFullYear()}-${formatMonth(currentDate)}-${formatDate(currentDate)}`,
      dateRange: `${twelveMonthsAgo.getFullYear()}-${formatMonth(twelveMonthsAgo)}-01 to ${currentDate.getFullYear()}-${formatMonth(currentDate)}-${formatDate(currentDate)}`,
      searchValue: '',
      selectedProductID: '',
      allBrands: [],
      selectedBrand: '',
      queryParams: {},
      queryParamsProductDetails: {},
      AProdcutsTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'turnover',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'sku', label: 'sku', sortable: true },
          { key: 'name', label: 'name', sortable: true },
          { key: 'manufacturer', label: 'manufacturer', sortable: true },
          { key: 'qty_sold', label: 'qty sold', sortable: true },
          {
            key: 'qty_sold_curr_year',
            label: 'qty sold current year',
            sortable: true,
          },
          {
            key: 'avg_pprice',
            label: 'avg. purchase price',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'avg_sprice',
            label: 'avg. sales price',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'turnover',
            label: 'turnover',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'turnover_curr_year',
            label: 'turnover current year',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'margin_p',
            label: 'margin %',
            sortable: true,
            formatter: val => `${this.$formatNumber(val)}`,
          },
          { key: 'orders_count', label: 'orders count', sortable: true },
          {
            key: 'share_total_sales',
            label: 'share of total sales',
            sortable: true,
            formatter: val => `${this.$formatNumber(val)}`,
          },
          { key: 'stock_qty', label: 'stock qty', sortable: true },
          { key: 'min_stock', label: 'min stock level', sortable: true },
          {
            key: 'last_order_date',
            label: 'last order date',
            sortable: true,
            formatter: value => {
              const new_date = new Date(value);
              if (value) {
                return new_date.toLocaleDateString('de-DE');
              }
              return '';
            },
          },
          { key: 'suppliers_count', label: 'different suppliers', sortable: true },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      productDetailsTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'price_netto',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'supplier', label: 'supplier', sortable: true },
          { key: 'supplier_sku', label: 'supplier sku', sortable: true },
          {
            key: 'price_netto',
            label: 'price',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          { key: 'qty', label: 'supplier stock qty', sortable: true },
          {
            key: 'delivery_time',
            label: 'delivery time',
            sortable: true,
            formatter: val => `${val} days`,
          },
          { key: 'link_to_product', label: 'link', sortable: true },
        ],
        /* eslint-disable global-require */
        items: [],
      },
    }
  },
  async created() {
    const dateRange = this.dateRange.split(' to ');
    this.queryParams.from_date = dateRange[0];
    this.queryParams.to_date = dateRange[1];

    try {
      await this.getAllBrands();
      await this.getAProdcuts();
    } catch (error) {
      if (error.response.status === 401) {
        this.$router.push({ name: 'login' });
      }
    }
  },
  methods: {
    async makeRequest(url, params) {
      return axios.get(url, {
        headers: {
          Authorization: `JWT ${this.$store.state.jwt}`,
          'Content-Type': 'application/json',
        },
        params,
      });
    },
    async getAllBrands() {
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/all-manufacturers/`, {});
        this.allBrands = response.data.results.map(item => item.value);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      }
    },
    async getAProdcuts() {
      this.show = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/a-products/`, this.queryParams);
        const results = response.data.results;
        if (Array.isArray(results) && results.length > 0) {
          this.AProdcutsTable.items = results;
          this.AProdcutsTable.totalRows = results[0].count * 2;
        }
        else {
          this.AProdcutsTable.items = [];
          this.AProdcutsTable.totalRows = 0;
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.show = false;
      }
    },
    async getProductDetails() {
      this.showProductDetails = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/product-details/`, this.queryParamsProductDetails);
        const results = response.data.results;
        if (Array.isArray(results) && results.length > 0) {
          this.productDetailsTable.items = results;
          this.productDetailsTable.totalRows = results[0].count * 2;
        }
        else {
          this.productDetailsTable.items = [];
          this.productDetailsTable.totalRows = 0;
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showProductDetails = false;
      }
    },
    async changeDateRange() {
      const dateRange = this.dateRange.split(' to ');
      if (dateRange.length > 1 && this.dateRange != this.oldDateRange) {
        this.queryParams.from_date = dateRange[0];
        this.queryParams.to_date = dateRange[1];

        await this.getAProdcuts();

        this.oldDateRange = this.dateRange;
      }
    },
    async searchProduct() {
      this.queryParams.page = 1;
      this.queryParams.search = '%' + this.searchValue.toLowerCase() + '%';
      await this.getAProdcuts();
    },
    async changeSelectedBrand() {
      this.queryParams.brand = this.selectedBrand;
      await this.getAProdcuts();
    },
    async sortChanged(value) {
      if (value.sortDesc === true) {
        this.queryParams.ordering = `-${value.sortBy}`;
      } else {
        this.queryParams.ordering = value.sortBy;
      }
      await this.getAProdcuts();
    },
    async handleAProdcutsTablePageChange(value) {
      this.queryParams.page = value;
      await this.getAProdcuts();
    },
    async handleProductDetailsTablePageChange(value) {
      this.queryParamsProductDetails.page = value;
      await this.getProductDetails();
    },
    async exportTable() {
      try {
        this.showExportStart = true;
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/all-a-products/`, this.queryParams);
        const exportedData = response.data.results;
        this.showExportStart = false;
        // Convert the data to a worksheet
        let worksheet = XLSX.utils.json_to_sheet(exportedData);
        // Create a new workbook, and add the worksheet to it
        let workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
        // Export the workbook to an xlsx file
        XLSX.writeFile(workbook, 'AProducts.xlsx');
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      }
    },
    productDetailsRowClicked(row) {
      this.selectedProductID = row.id;
      this.queryParamsProductDetails.id = this.selectedProductID;
      this.getProductDetails();
      this.$refs['product-details-modal'].show();
    },
    resetProductDetailsModal() {
      this.selectedProductID = '';
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>